.c-btn--action {
  z-index: 0;
  &:before {
    content:'';
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    display: inline-block;
    right: 100%;
    top: 0;
    vertical-align: middle;
    z-index: -1;
    transition: all 0.3s ease;
    clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%);
    -webkit-clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%);
  }

  &:hover {
    &:before {
      content:'';
      right: 1%;
      transition: all 0.3s ease;
    }
  }
}

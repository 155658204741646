.c-turntable {
    width: 100%;
    max-width: 100%;
    height: 100%;
    z-index: 9999;
    position: relative;

    @include sb-media($bp-lg) {
        min-width: 500px;
        max-width: 66%;
    }
}
.c-card {
  position: relative;
  font-family: "Muli", sans-serif;
  font-weight: 300;
  text-decoration: none;
  max-width: 90%;
  width: 100%;
  padding: 2em;
  background-color: $c-white;
  color: $navy-blue;
  margin-bottom: 1em;
  display: inline-block;

  &.flex {
    @include inline-flex();
    align-items: center;
    justify-content: space-between;
  }

  @include box-shadow($card-shadow);

  @include sb-media($bp-med) {
    display: inline-block;
    max-width: 80%;
    margin: 0 1em;
    margin-bottom: 1em;
  }

  &:hover {
    &:before {
      content: '';
      position: absolute;
      background-color: rgba(0, 0, 0, 0.1);
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  &__title {
    letter-spacing: 0.1em;
    color: $coral;
    min-width: 33%;
    padding: 0 1em;
    padding-left: 0;
  }

  &__content {
    padding: 1em;
    max-width: 80%;
    display: inline-block;

    &.flex {
      display: inherit;
      padding: 1em 0;
      width: 100%;
      min-width: 100%;
      flex-direction: row;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__image {
    @include flex();
    width: 100%;
  }

  &__description {
    padding: 0.75em 0;
  }

  &__latest, &__date  {
    position: absolute;
    text-align: center;
    background-color: transparentize($coral, 0.25);
    color: $c-white;
    padding: 0.5em;
    font-size: $fs-sm;
    font-weight: $fw-thick;
    @include flex();
    justify-content: center;

    @include sb-media($bp-sm) {
      font-size: $fs-base;
      padding: 0.5em 0.75em;
    }
  }

  &__latest {
    top: 0;
    left: 0;
    letter-spacing: 0.2em;
    text-transform: capitalize;
  }

  &__date {
    top: 0;
    right: 0;
    max-width: 7.5%;
  }
}

.c-btn {
  vertical-align:   middle;
  padding: 1.5rem 2rem;
  background-color: $navy-blue;
  text-transform: uppercase;
  color: #fff;
  border: none;
  font-family: "Muli", sans-serif;
  font-weight: 300;
  letter-spacing: .05em;
  font-size: 0.9em;
  position: relative;
  line-height: 20px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  width: 100%;

  &:active, &:focus {
    background-color: $leather;
    @include outline(none);
  }

  &:hover {
    background-color: transparentize($navy-blue, 0.2);
    transition: all 0.2s ease-in-out;
    cursor: pointer;

  }

  &--light {
    background-color: $leather;

    &:hover {
      background-color: transparentize($leather, 0.2);
    }
  }

  &--large {
    background-color: $coral;
    font-size: 1.25em;
    width: 100%;

    &:hover {
      background-color: transparentize($coral, 0.2);
    }
  }

  &--small {
    max-width: 100px;
    height: 40px;
    padding: 0;
  }

  &--toggle {
    position: relative;
    z-index: 0;
    max-width: 100px;


    // &:hover {
    //   background-color: transparentize($navy-blue, 0);
    // }
    // &:focus {
    //   $background-color: $leather;
    // }

    &::before {
      height: 100%;
      width: 100%;
      z-index: 1;
      left: -80%;
      top: 0;
      position: absolute;
      content:'';
      z-index: 1;
      background-color: $leather;
      transition: all 0.3s ease-in-out;
      -webkit-clip-path: polygon(0% 0%, 80% 0, 100% 50%, 80% 100%, 0% 100%);
      clip-path: polygon(0% 0%, 89% 0, 100% 50%, 90% 100%, 0% 100%);
    }

    &::after {
      z-index: 2;
      content:'Menu';
      position: relative;
    }

    &.active {
      background-color: $leather;
      &::before {
        left: 0;
        background-color: $navy-blue;
      }
      &:hover {
        &::before {
          left: 0;
          background-color: transparentize($navy-blue, 0.2);
        }
      }
    }
  }

}

// Fonts
@import url('https://fonts.googleapis.com/css?family=Muli:300,400,500');
@import url(https://fonts.googleapis.com/css?family=Varela+Round|Open+Sans:300);

$f-muli: 'Muli', sans-serif;
$f-varela: "Varela Round", sans-serif;

// Weights
$fw-light: 300;
$fw-med: 400;
$fw-thick: 500;
$fw-xthick: 600;

// Sizes
$fs-xxl: 4rem;
$fs-xl: 3.5rem;
$fs-lg: 3.0rem;
$fs-med: 1.5rem;
$fs-base: 1rem;
$fs-sm: 0.75rem;

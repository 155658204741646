.c-feature-promo {
    @include flex();
    flex-direction: row;
    flex-wrap: wrap;
    background-color: $eggshell;
    min-width: 100%;
    margin: 1em 0;
    @include box-shadow($feature-shadow);
    @include transition(all 0.3s ease-in-out);

    &:hover {
        @include box-shadow($feature-hover-shadow);
        // @include transform(scale(1.005));
    }

    &__title {
        padding: 1em 0 1em 1em;
        background-color: $navy-blue;
        color: $c-white;
        width: 100%;
    }

    &__content {
        width: 100%;
        min-width: 500px;
        min-height: 200px;
        padding: 1em;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        color: $c-white;
        z-index: 2;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0,0,0,0.5);
            z-index: -1;
        }
    }

    &__action {
        &:hover {
            cursor: context-menu;
        }
    }
}
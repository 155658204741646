.c-link {
  outline:none;
  display: inline-block;
  position: relative;
  text-align: center;
  z-index: 1;
  height: 142px;
  line-height: 142px;
  width: 142px;
  color: $c-white;
  text-decoration: none;
  text-transform: uppercase;

  &:focus {
    div {
      background-color: rgba(255, 83, 61, 0.8);
    }
  }

  &:active {
    .c-link__shape {
      background-color: transparentize($leather, 0.2);
      border: 1px solid $coral;
    }
  }


  &__shape {
    border: solid 1px $leather;
    z-index: -1;
    position: absolute;
    border: solid 1px #AB987A;
    left: 50%;
    top: 50%;
    margin: 0;
    transition: all 0.1s ease-in-out;

    &:visited {
      background-color: $leather;
      border: 1px solid $coral;
    }

    &:hover, &:focus {
      background-color: transparentize($coral, 0.2);
    }
  }

  &.not-active {
      transition: all 0.3s ease-in-out;
    &:active {
      .c-link__shape {
        background-color: $coral;
        border: 1px solid $leather;
      }
    }

    .c-link__shape {
      background-color: $coral;
    }
  }
}

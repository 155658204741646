.t-inputs {
  padding: 0;
  padding-bottom: 0.65em;
  display: flex;
  max-width: 500px; 
  width: 100%;
  font-size: $fs-base;
  position: relative;

    .required {
      &:before {
        content: "*";
        position: absolute;
        left: -5%;
        top: 50%;
        height: 50%;
        font-size: $fs-med;
        color: $coral;
        @include transform(translateY(-50%));
      }
    }

    input, textarea {
      resize: none;
      @include box-shadow($inputs-shadow);
      min-width: 300px;

      max-width: 500px;
      padding: 1em;
      letter-spacing: 0.05em;

      &.req {
        &:before {
          content: "*";
          position: absolute;
          left: 0;
          color: $coral;
          font-size: $fs-lg;
        }
      }
    }

    label {
      display: block;
      padding: 1em 0;
    }

    input {
      min-width: 300px;
      max-width: 500px;
      width: 100%;
    }
    input[type=text] {
      border: 1px inset;
      padding: 0.65em;
      @include sb-media($bp-med) {
        padding: 0.65em 1em;
      }
    }
}

$wrapper-height: 80%;
$wrapper-width: 88.5%;

.c-modal {
    @include flex();
    position: fixed;
    overflow: hidden;
    width: 0%;
    height: 0%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    top: 50%;
    left: 50%;
    // align-content: center;
    align-items: center;
    justify-content: center;
    @include transition(all 0.1s ease); 

    &.opened {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        font-weight: $fw-thick;
        color: $c-white;
        border-radius: 50%;
        background-color: $navy-blue;
        text-align: center; 
        // padding: 0.5em 0.5em 0 0;
        padding: 0.5em 0.75em;
        @include transform(translate(50%, -50%));

        &:hover {
            cursor: pointer;
        }

    }

    &__closearea {
        @include flex();
        height: 100%;
        width: 100%;
        z-index: 9999;
        background-color: transparent;
        &:hover {
            cursor: alias;
        }
    }

    &__wrapper {
        @include flex();
        height: $wrapper-height;
        width: $wrapper-width;
        top: $sp-header-offset + 2rem;
        z-index: 999999;
        position: absolute;
        background-color: white;
        border-radius: 0.25em;

        @include sb-media($bp-xl) {
            max-width: 65%;
        }
    }

    &__content {
        width: 100%;
        padding: 1em;
    }

    &__header {
        padding: 1em 0 0 0;
        background-color: $navy-blue;
        color: $c-white;
        width: 100%;
        max-width: 20%;
        left: 0;
        text-align: center;
        font-size: $fs-med;
    }
    
    &__description {
        margin: 1em 0;
        margin: 1em 0em 0 0em;
        padding: 1em;
        background-color: $coral;
        height: auto;
        font-size: $fs-base;
        text-align: left;

        // min-height: 10%
    }

    &__actions {

    }

    &__embed {
        width: 100%;
        height: 100%;
        padding: 1em 0;
        max-height: calc(100% - 10%);
    }
}
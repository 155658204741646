.c-image {
  height: 150px;
  width: 150px;
  overflow: hidden;
  @include inline-flex();
  align-items: center;
  justify-content: center;

  @include sb-media($bp-lg) {
    @include inline-flex();
    max-width: 500px;
    justify-content: flex-start;
  }

  img {
    max-width: 100%;
    width: 100%;
    min-height: 100%;
  }

  &.large {
    height: 500px;
    width: 500px;
  }

  &.medium {
    height: 300px;
    width: 300px;
  }

  &.circle {
    border-radius: 50%;
    overflow: hidden;
  }

}

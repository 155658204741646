.c-play-btn {
    margin: 1em;
    box-sizing: border-box;
    width: 74px;
    height: 74px;
    background-color: $coral;
    border-color: transparent transparent transparent #202020; // makes the shape
    transition: 100ms all ease;
    will-change: border-width;
    cursor: pointer;
  
    // play state
    border-style: solid;
    border-width: 37px 0 37px 60px;
    @include outline(none);

        &.small {
            width: 36px;
            height: 36px;
            border-width: 18px 0 18px 30px;
        }
    height: 100%;
  
   &.play {
    border-style: solid;
    border-color: #202020;
    box-sizing: border-box;
    border-color: transparent transparent transparent #202020;
    border-width: 37px 0px 37px 74px;
        &.small {
            border-width: 18px 0px 18px 36px;
        }
   } 

   &.pause {
    border-style: double;
    height: 36px;
    border-width: 0px 0 0px 60px;
        &.small {
            border-width: 0px 0 0px 30px;
        }
   }
}
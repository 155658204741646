// Colors
$c-white: #fff;

$navy-blue: #0F1626;
$leather: #AB987A;
$coral: #FF533D;
$eggshell: #F5F5F5;

$knob-color: #181818;
$light-color: #a8d8f8;

$pacman: #FFEE00;

.c-header-menu {
  width: 300px;
  height: 100%;
  transform: translate(-110%, -15%);
  transition: all .3s ease;
  background-color: transparentize($navy-blue, 0.2);
  position: absolute;
  min-width: none;

  @include sb-media($bp-lg) {
    clear: both;
    transform: translateY(-200%);
    width: 100%;
    min-width: 100%;
    height: 426px;
  }

  &__toggle {
    z-index: 1000000;
    @include sb-media($bp-lg) {
      clear: both;

    }
  }

  &__info {
    display: none;
    color: $c-white;
    font-family: "Muli", sans-serif;
    font-weight: 300;

    @include sb-media($bp-lg) {
      display: block;
      position: absolute;
      left: 10%;
      top: $sp-header-offset * 2;
      min-width: 200px;
      max-width: 300px;

      & div {
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }

  &__nav {
    height: inherit;
    width: 100%;
    height: 75%;
    position: relative;
    margin: 0;
    transform: translateX(-110%);

    @include sb-media($bp-lg) {
      width: 60%;
      height: 100%;
      margin: 0 auto;
      float: right;
      transform: translateX(0%);
    }
  }

  &__list {
    list-style-type: none;
    display: inline-block;
    width: 100%;
    height: 100%;
    position: relative;
    transition: all 0.3s ease;

    @include sb-media($bp-lg) {
      width: 55%;
      transform: translateX(20%);
    }

  }

  &__item {
    display: inline;
    height: 142px;
    width: 142px;
    position: absolute;
    transition: all 0.3s ease;
    left: -20%;

    @include sb-media($bp-lg) {
      left: 0;
    }
  }

  &.active {
    // transform: translate(0%, -15%);
    transform: translate(0%, 0%);
    z-index: 5;

    .c-header-menu__nav {
      transition: all 0.3s ease;
      transform: translate(-110%, -20%);
      overflow: hidden;


      @include sb-media($bp-lg) {
        transform: translateX(0%);
      }

      &.active {
        // transform: translateX(0%);
        @include transform(translate(0%, -30%));

        @include sb-media($bp-lg) {
          @include transform(translate(0%, -20%));
        }

        .c-header-menu__list {
          // transition: all 0.3s ease;
          @include transition(all 0.3s ease);
          @include transform(translateY(20%));
          // transform: translateY(20%);
        }
      }
    }
  }
}

$no-mq-support: false !default;
$serve-to-nomq-max-width: 70em !default;

@mixin sb-media($query) {
  @if $no-mq-support{
    @if $query < $serve-to-nomq-max-width{
      @content;
    }
  } @else {
    @media ( min-width: + $query ) {
      @content;
    }
  }
}

@mixin transform-y($value) {
  transform: translateY($value);
  -webkit-transform: translateY($value);
  -ms-transform: translateY($value);
  -moz-transform: translateY($value);
  -o-transform: translateY($value);
}

@mixin transform-x($value) {
  transform: translateX($value);
  -webkit-transform: translateX($value);
  -ms-transform: translateX($value);
  -moz-transform: translateX($value);
  -o-transform: translateX($value);
}

@mixin transition($args...) {
  transition: $args;
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
}

@mixin transform($args...) {
  transform: $args;
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
}

@mixin box-sizing($value) {
       box-sizing:  border-box;
    -ms-box-sizing: border-box;
   -moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
}

// @mixin box-shadow($values) {
//   @each $var in $values {
//             box-shadow: #{$var};
//     -webkit-box-shadow: #{$var};
//        -moz-box-shadow: #{$var};
//   }
// }

@mixin box-shadow($args...) {
 
            box-shadow: $args;
    -webkit-box-shadow: $args;
       -moz-box-shadow: $args;
  
}

@mixin gradient($color: #F5F5F5, $start: #EEE, $stop: #FFF) {
  background: $color;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, $start), color-stop(1, $stop));
  background: -ms-linear-gradient(bottom, $start, $stop);
  background: -moz-linear-gradient(center bottom, $start 0%, $stop 100%);
  background: -o-linear-gradient($stop, $start);
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",$stop,$start));
}

@mixin columns($count, $width) {
         column-count: $count;
 -webkit-column-count: $count; /* Chrome, Safari, Opera */
    -moz-column-count: $count; /* Firefox */
         
        
         column-width: $width;
 -webkit-column-width: $width; /* Chrome, Safari, Opera */
    -moz-column-width: $width; /* Firefox */
        
}

@mixin page-break-inside($rule) {
  page-break-inside: $rule; /* Firefox */
  break-inside: $rule; /* IE 10+ */
  -webkit-column-break-inside: $rule; /* Chrome, Safari, Opera */

}

@mixin outline($rule) {
  outline: $rule; /* IE 10+ */
  -webkit-outline: $rule; /* Chrome, Safari, Opera */
     -moz-outline: $rule; /* Firefox */

}

@mixin flex() {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin inline-flex() {
  display: -webkit-inline-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-inline-flexbox;  /* TWEENER - IE 10 */
  display: inline-flex;         /* NEW, Spec - Firefox, Chrome, Opera */
}

// Breakpoints
$bp-xs: (325/16) * 1em; //22.5em ~ mobile
$bp-sm: (450/16) * 1em; //28.125em ~ hi-res mobile/low-res tablet
$bp-med: (720/16) * 1em; //45em ~ laptop/tablet
$bp-lg: (960/16) * 1em; // 60em ~ desktop
$bp-xl: (1500/16) * 1em; //87.5em ~ hi-res desktop

$sp-header-max-height: 68px;
$sp-header-offset: 4.25rem;
$sp-content-padding: 1em;

// Easing Variables
$quick: cubic-bezier(0,0,.3,1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);

// Shadows
$accordion-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$accordion-hover-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
$card-shadow: 1px 5px 14px rgba(0,0,0,0.5);
$card-hover-shadow: 1px 5px 14px rgba(0,0,0,0.5), 0px -5px 40px rgba(0,0,0,0.5);
$inputs-shadow: inset -1px -1px 11px 0px rgba(0,0,0,0.1);
$feature-shadow: $card-shadow;
$feature-hover-shadow: $card-hover-shadow;
.c-header {
  max-height: $sp-header-max-height;

  &.sticky {
    position: fixed;
    width: 100%;
    z-index: 999999;

    @include sb-media($bp-lg) {
      @include inline-flex();
    }

    .c-header-menu {
      position: fixed;
    }

    a:hover {
      @include outline(none);
    }
  }


  #logo {
    display: block;
    width: 100%;
    min-width: 100%;
    text-align: left;

    @include sb-media($bp-lg) {
      @include inline-flex();
      flex-direction: row;
      flex-wrap: nowrap;
      min-width: inherit;
      width: 100%;
      text-align: center;
    }
  }

  &__item {
    display: block;
    max-width: 300px;

    @include sb-media($bp-lg) {
      display: inline;
      width: 100%;
      max-width: inherit;
      float: right;
    }
  }

  &__link {
      display: inline-block;
      width: 100%;
      text-decoration: none;
      @include outline(none);

      &:focus {
        background-color: $leather;
      }
      button {
        @include outline(none);
      }
  }

  &__list {
    display: block;
    width: 100%;
    @include sb-media($bp-lg) {
      display: inline-flex;
    }
  }


  &__nav {

    transform: translateX(-110%);
    transition: all 0.3s ease;
    //transition-delay: 0.s;

    @include sb-media($bp-lg) {
      display: inline-flex;
      width: 100%;
    }

    &.active {
      transform: translateX(0%);
      @include sb-media($bp-lg) {
        display: inline-flex;
        transform: translateX(0%);
      }
    }

    @include sb-media($bp-lg) {
      transform: translateX(0%);
    }
  }
  .c-btn--toggle.c-header-menu__toggle {
    position: absolute;
    top: 0;
    right: 0;
  }

}

.o-shape {
  width: 250px;
  height: 250px;
  position: relative;
  display: inline-block;

  &--rhombus {
    height: 99.08px;
    width: 99.08px;
    background-color: $coral;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    //transform: rotateX(45deg);
  }

  &--rhombus-rotate {
    height: 99.08px;
    width: 99.08px;
    background-color: $coral;
    transform:  translateX(-50%) translateY(-50%) rotate(-45deg);
  }

  &--pacman {
     width: 0px;
     height: 0px;
     border-right: 60px solid transparent;
     border-top: 60px solid $pacman;
     border-left: 60px solid $pacman;
     border-bottom: 60px solid $pacman;
     border-top-left-radius: 60px;
     border-top-right-radius: 60px;
     border-bottom-left-radius: 60px;
     border-bottom-right-radius: 60px;
     transition: all 0.01s ease;

     &:hover {
       	width: 0px;
        height:0px;
        background: $pacman;
        border-radius: 49%;
        -moz-border-radius: 49%;
        -webkit-border-radius: 49%;
     }
  }

  &--circle-star {
    height: 100px;
    width: 100px;
    background-color: $coral;
    transition: all 0.3s ease;
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);

    &:hover {
      transition: all 0.3s ease;
      -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
      clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    }
  }
}

.c-playlist {
    width: 100%;
    max-width: 90%;
    height: 100%;
    background-color: $navy-blue;
    padding: 1em;
    flex-direction: column;
    align-items: center;

    color: $c-white;
    font-family: "Muli", sans-serif;
    font-weight: 300;

    @include sb-media($bp-lg) {
        min-width: 150px;
        max-width: 30%;
    }


    &__track {
        background-color: $coral;
        border: 2px solid $coral;
        border-style: inset;
        width: 100%;
        margin: 0.1em;
        position: relative;
        min-height: 120px;
        height: 100%;
    }

    &__play {
        position: relative;
        width: 100%;
        min-height: 100%;

        &::before {
            display: none;
            content: '';
            background-color: rgba(0,0,0,0.1);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 9999;
        }

        &:hover {
            &::before {
                display: block;
            }
        }
    }

    &__toggle {
        width: 10%;
        position: relative;
        left: 25%;
        @include transform(translate(-25%, 50%));
    }
    
    &__link {
        display: inline-flex;
        position: absolute;
        max-height: 100%;
        max-width: 20%;
        min-height: 100%;
    }

    &__controls {
        @include flex();
        width: 80%;
        align-items: flex-end;
        margin: 0 0 0 20%;
        min-height: 100%;
        position: absolute;
        right: 0;
    }

    &__title {
        min-width: 100%;
        position: absolute;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-height: 25%;
        top: 30%;
        left: 50%;
        @include transform(translateX(-50%));
    }

    &__progress {
        display: flex;
        width: 100%;
        max-width: 90%;
        padding: 1em 0;
        margin: 0 auto;
        margin-left: 0;
    }
}
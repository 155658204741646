.c-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__group {
        display: flex;
        max-width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        @include sb-media($bp-med) {
            @include flex();
            flex-direction: row;
            justify-content: space-evenly;
            padding: 1em 0;
            padding-bottom: 1em;
        }
        
    }
}
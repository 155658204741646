.c-about {
  @include flex();
  flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
  font-size: $fs-med;
  font-weight: $fw-light;
  // justify-content: flex-start;
  justify-content: space-around;

  @include sb-media($bp-med) {
    // justify-content: flex-start;
    @include inline-flex();
    flex-direction: row;
  }


  div {
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }

  &__image {
    @include inline-flex();
    max-width: 100%;
    width: 100%;
    justify-content: center;


    @include sb-media($bp-med) {
      max-width: 50%;
      width: auto;
    }
  }

  &__body {
    display: block;
    width: auto;
    justify-content: center;
    padding: 1em;
    padding-bottom: 0;

    @include sb-media($bp-med) {
      @include inline-flex();
      max-width: 50%;
    }
  }

  &__content {
    @include flex();
    flex-direction: column;
    padding: 1em;
    // align-content: flex-end;
    // justify-content: space-between;
    // align-items: flex-end;

     & .c-accordion {
       margin: 1em;
     }
  }

  &__wrapper {
    @include flex();
    flex-direction: column;
    justify-content: space-between;

    // @include sb-media($bp-med) {
    //   flex-direction: row;
    // }
  }
}

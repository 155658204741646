.c-feature-list {
    list-style-type: none;
    background-color: $eggshell;
    padding: 1em;
    height: 100%;



    &__item {
        padding: 0.5em;
        padding-left: 0;

        &:before {
            content: "—";
            color: $navy-blue;
            padding-right: 0.5em;
            font-size: $fs-med;
            font-weight: $fw-thick;
        }
    }

    &.dark {
        background-color: $navy-blue;

        .c-feature-list__item {
            color: $eggshell;

            &:before {
                color: $coral;
            }
        }
    }

    &.thick-bullets {
        .c-feature-list__item {
            &:before {
                font-weight: $fw-xthick;
            }
        }
    }

    &.indent {

        .c-feature-list__item {
            padding: 0.5em;
            padding-left: 0;
            text-indent: 2em;
            display: grid;
            position: relative;
            left: 1em;
            &:before {
                position: absolute;
                text-indent: 0;
                display: block;
                left: -1.5em;
                // top: 50%;
                // transform: translateY(-50%);
            }
        }

        &.center {
            .c-feature-list__item {
                &:before {
                    top: 50%;
                    transform: translateY(-50%);
                }
            } 
        }
    }



}


// padding: 0.5em;
// text-indent: 2em;
// display: grid;
// position: relative;
// left: 1em;

// &.center {

//     &:before {
//         content: "—";
//         color: #0F1626;
//         padding-right: 0.5em;
//         font-size: 1.5rem;
//         font-weight: 500;
//         /* width: 100%; */
//         /* max-width: 30px; */
//         position: absolute;
//         text-indent: 0;
//         display: block;
//         left: -1.5em;
//         /* line-height: 50%; */
//         top: 50%;
//         transform: translateY(-50%);
//     }
// }
// Containers
.o-max-width {
  margin: 0 auto;
  max-width: (950 / 16) * 1em;
}

.o-max-width-lg {
  margin: 0 auto;
  max-width: (1500 / 16) * 1em;
}

.o-max-width-sm {
  margin: 0 auto;
  max-width: (450 / 16) * 1em;
}

.o-top-layer {
  z-index: 9999;
  position: relative;
}

.o-container {
  height: 100%;
}

.o-content {
  padding: 0 $sp-content-padding;
  padding-top: $sp-header-offset*1.5;
  height: auto;
  min-height: 100%;
  background-color: $eggshell;
  @include flex();
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  width: calc(100% - 2em); // uses $sp-content-padding * 2

  @include sb-media($bp-lg) {
    flex-direction: row;
  }

  &.center {
    align-items: center;
  }

  h1, h2, h3, h4, h5, h6 { font-weight: $fw-thick; }
  h1 { font-size: $fs-xxl }
  h2 { font-size: $fs-xl }
  h3 { font-size: $fs-lg }
  h4 { font-size: $fs-med }
  h5 { font-size: $fs-base }
  h6 { font-size: $fs-sm }
}


.o-columns {

  &.flex {
    @include flex();
     .o-columns__item {
      @include inline-flex();
    }
  }
 
  @include columns(1, $bp-sm/2);

  @include sb-media($bp-med) {
    @include columns(2, $bp-sm/2);
  };

  @include sb-media($bp-lg) {
    @include columns(2, $bp-med/3);
  }

  &.three {
    @include sb-media($bp-med) {
      @include columns(3, $bp-sm/3);
    };
  
    @include sb-media($bp-lg) {
      @include columns(3, $bp-med/3);
    }
  }
  

  &__item {
    @include page-break-inside(avoid);
    @include inline-flex();
    width: 100%;

    @include sb-media($bp-med) {
      max-width: 100%;
      display: inline-block;
    }

  }

}

.o-full-width {
  width: 100%;
}

.o-max-width-33 {
  max-width: 33%;
}

.o-max-width-66 {
  max-width: 66%;
}

.o-flex {
  @include flex();

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  &.center {
    align-items: center;
  }

  &.space-a {
    justify-content: space-around;
  }

  &.middle {
    justify-content: center;
  }
}

.o-inline-flex {
  @include inline-flex();
}

.c-accordion {
    position: relative;
    height: 100%;
    @include transition(all .25s $easeInOutQuart);
    @include box-shadow(1px 5px 14px rgba(0,0,0,0.1));

    .material-box-shadow {
        @include box-shadow($accordion-shadow);
        @include transition(all 0.3s cubic-bezier(.25,.8,.25,1));
        
        &:hover {
            @include box-shadow($accordion-hover-shadow);
        }
    }

    &.light-shadow {
        @include box-shadow(1px 1px 0px 0px rgba(0, 0, 0, 0.1));
    }

    &.light-shadow-2 {
        @include box-shadow(0.25px 0.25px 0px 2px rgba(0, 0, 0, 0.1));
    }

    &.active {

    }

    &__toggle {
        @include outline(none);
        text-decoration: none;
        @include flex();
        padding: 1em;
        position: relative;

        &:visited {
            color: inherit;
        }

        &:active, &:focus {
            background-color: $leather;
            color: $eggshell;
        }

        &:after {
            @include transition(all 0.25s ease);
            @include transform(translateY(-50%));
            content: "^";
            position: absolute;
            right: 2em;
            top: 50%;
            font-size: $fs-med;
            font-weight: $fw-thick;
        }

        &.active {
            background-color: $coral;
            color: $eggshell;

            &:active, &:focus {
                background-color: $leather;
                color: $eggshell;
            }

            &:after {
                @include transform(translateY(-50%) rotate(180deg));
            }
        }
    }

    &__content {
        @include transition(all 0.25s $easeInOutQuart);
        overflow: hidden;
        // height: 0px;
        // min-height: 0px;
        // // max-height: 0px;  

        height: 0px;
        min-height: 0px;
        max-height: 0px;  



        &.opened {
            overflow: auto;
            // min-height: 500px;
            // // max-height: 500px;
            // // height: 100%;

            min-height: 100px;
            max-height: 1000px;
            height: 100%;
        }
    }
}